@import "../colors";

.chart-columns_tab {
    height: 100%;
    display: flex;
    flex-direction: row;

    .chart_columns_tab-column {
        overflow-y: scroll;
        border-left: 1px solid $color-light-border;
        background-color: white;
        width: 230px;
        max-width: 230px;
        min-width: 230px;

        overflow-x: hidden;
        max-height: 100vh;
    }

    .chart_columns_tab-column-config {
        //Menu block
        .config-drag {
            .config-subheader-icon-ok {
                color: green;
            }

            //Failed ok for axis
            &.config-drag-empty {
                .drop-zone {
                    min-height: 50px;
                }
            }

            //When we can add more items but it's not empty
            &.config-drag-ok:not(.config-drag-empty) {
                .drag_here {
                    height: auto;
                    margin-top: 5px;
                }
            }
        }

        .zone-disabled {
            background: rgba(0, 0, 0, 0.2);
            height: 45px;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 12px;
        }

        .menu_block-body {
            padding: 0;
        }

        .drop-zone {
            padding: 5px;

            .drag_here {
                height: 40px;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 12px;
            }

            &.drag-over {
                background-color: rgba(10, 208, 10, 0.1) !important;

                &.drag-fail {
                    background-color: rgba(208, 10, 33, 0.1) !important;
                }
            }
        }

        .drop-item {
            padding: 5px 6px;
            margin-top: 5px;

            &:first-child {
                margin-top: 0;
            }

            border: 1px solid $color-light-blue-border;
            border-radius: 4px;
            background-color: #ffffff;
            font-size: 12px;

            .drop-item-line {
                display: flex;
                width: 100%;

                .drop-item-name {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: bold;
                    color: $color-black;
                }

                .drop-item-remove {
                    margin-left: auto;
                    cursor: pointer;
                }
            }

            .drop-item-config-expanded {
                display: flex;
                width: 100%;
                flex-direction: column;

                margin-top: 5px;
                border-top: 1px solid $color-light-border;
                padding-top: 5px;

                .drop-item-config-param {
                    display: flex;
                    width: 100%;
                    flex-direction: row;

                    &:not(:first-child) {
                        margin-top: 3px;
                    }

                    .name {
                        min-width: 50px;
                        line-height: 20px;
                        font-size: 11px;
                        padding-left: 3px;
                    }

                    .value {
                        width: 100%;
                        height: 20px;

                        &.flex-value {
                            display: flex;
                            align-items: center;

                            > :not(:first-child) {
                                margin-left: 3px;
                            }
                        }

                        input[type="text"] {
                            line-height: 14px;
                            font-size: 12px;
                            padding: 2px 4px;
                            border: 1px solid $color-light-border;
                        }
                    }
                }
            }

            .drop-item-config-line {
                display: flex;
                width: 100%;

                .config-param {
                    margin-left: 5px;
                    margin-top: 6px;

                    &.ant-btn {
                        height: 26px;
                        padding: 4px 0;
                        box-shadow: none;
                        border: none;
                        line-height: 1;

                        .anticon {
                            vertical-align: 0;
                            font-size: 13px;
                        }
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            .ant-select {
                margin: 6px 0 0 0;
                font-size: 11px;
                width: 100%;

                .ant-select-selector {
                    padding: 5px 8px;
                    height: 26px;
                    border: 1px dotted $color-light-border;

                    .ant-select-selection-item {
                        line-height: normal;
                    }
                }
            }
        }
    }

    .chart_columns_tab-column-list {
        .menu_block-body {
            min-height: 60px;
        }
    }
}
