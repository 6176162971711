$color-border: rgba(0, 0, 0, 0.1);
$color-border-dark: rgba(0, 0, 0, 0.2);

$color-header: rgb(245, 247, 247);

.table-container {
    height: 100%;

    &.ag-theme-balham {
        //.ag-ltr {

        .ag-root-wrapper {
            border-radius: 0;
            border: 1px solid $color-border;
        }

        .ag-header {
            border-bottom: solid 1px $color-border;
        }

        .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
            border-right: solid 1px $color-border;
        }

        .ag-pinned-left-header {
            border-right: solid 1px $color-border;
        }

        .ag-pinned-left-cols-container {
            .ag-cell {
                background: $color-header;
            }
        }

        .ag-header-cell {
            background-color: $color-header;
        }

        .ag-row {
            border-top: solid 1px $color-border;

            &.ag-row-first {
                border-top: none;
            }
        }

        .ag-cell {
            border-right: 1px solid $color-border !important;
        }
    }
}
