@import "colors";

.DashboardPage {
    display: flex;
    height: 100vh;
    width: 100vw;

    header {
        height: 60px;
        line-height: 60px;
        padding: 0;
        display: flex;

        .logo-container {
            width: 220px;
            text-align: center;

            &.cropped {
                width: 80px;

                .logo img {
                    width: 34px;
                }
            }

            .logo {
                img {
                    height: 20px;
                    margin-bottom: 4px;
                    object-fit: cover;
                    object-position: left;
                }
            }
        }

        .right-expander {
            margin-left: auto;
            color: white;
            text-align: center;
            width: 220px;
            border-left: 1px solid $color-10p-black;
            cursor: pointer;

            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }

            &.cropped {
                width: 30px;
            }
        }
    }

    //.content-layout {
    //    .ant-menu-inline {
    //        border: none;
    //    }
    //
    //    .left-menu {
    //        overflow-x: hidden;
    //    }
    //
    //    .left-menu,
    //    .right-menu {
    //        font-size: 12px !important;
    //
    //        .menu-item {
    //            margin: 0;
    //            font-size: 12px;
    //
    //            &.menu-add-element {
    //                font-size: 11px;
    //
    //                .anticon {
    //                    font-size: 12px;
    //                }
    //            }
    //
    //            &.menu-item-sql {
    //                font-weight: bold;
    //
    //                &:not(:hover) {
    //                    color: $color-black;
    //                }
    //            }
    //        }
    //
    //        .menu-add-element {
    //        }
    //
    //        .ant-menu-item-divider {
    //            margin: 0;
    //            background-color: $color-light-border;
    //        }
    //    }
    //
    //    .menu-header {
    //        position: relative;
    //        font-weight: bold;
    //        color: #636363;
    //        text-transform: uppercase;
    //        font-size: 11px;
    //        line-height: 11px;
    //        //border-bottom: 1px solid $color-10p-black;
    //        overflow: hidden;
    //        text-overflow: ellipsis;
    //        white-space: nowrap;
    //        padding: 8px 10px;
    //
    //        .icon {
    //            position: absolute;
    //            right: 0;
    //            top: 0;
    //            margin: 7px;
    //            color: $color-green;
    //            cursor: pointer;
    //
    //            &:hover {
    //                color: $color-green-hover;
    //            }
    //
    //            svg {
    //                height: 17px;
    //                width: 17px;
    //            }
    //        }
    //    }
    //
    //    .full-page-loader {
    //        width: 100%;
    //        height: 100%;
    //        display: flex;
    //        align-items: center;
    //        justify-content: center;
    //    }
    //}
    //
    //main {
    //    .title-container {
    //        background-color: #263238;
    //        border-bottom: 1px solid #303f45;
    //        padding: 7px 15px;
    //
    //        .title {
    //            color: #fff;
    //            font-weight: bold;
    //            font-size: 11px;
    //            margin: 0;
    //
    //            textarea {
    //                font-size: 12px;
    //                height: 22px !important;
    //                padding: 2px 6px;
    //                border: none;
    //                border-radius: 2px;
    //                margin: 2px 12px;
    //                max-width: 205px;
    //                resize: none;
    //                min-height: 22px;
    //            }
    //
    //            .ant-typography {
    //                color: #fff;
    //                font-weight: bold;
    //                font-size: 11px;
    //                margin: 0;
    //            }
    //        }
    //    }
    //
    //    .top-toolbar {
    //        padding: 7px 15px;
    //        background-color: #263238;
    //
    //        border-bottom: 1px solid #303f45;
    //
    //        .run-button {
    //            height: 26px;
    //            padding: 0 25px;
    //            background-color: $color-green;
    //            border-radius: 3px;
    //            border: none;
    //            box-shadow: none;
    //
    //            &::before {
    //                top: 0;
    //                left: 0;
    //                right: 0;
    //                bottom: 0;
    //            }
    //
    //            &:hover {
    //                background-color: $color-green-hover;
    //            }
    //        }
    //    }
    //
    //    .split-box {
    //        height: 100%;
    //    }
    //
    //    .gutter {
    //        background: #fff;
    //        border-top: 1px solid $color-light-border;
    //        border-bottom: 1px solid $color-light-border;
    //        cursor: row-resize;
    //    }
    //
    //    .top-panel {
    //        display: flex;
    //        flex-direction: column;
    //        overflow-x: hidden;
    //        min-height: 120px;
    //    }
    //}

    .data-loader,
    .data-load-error {
        border: none;
    }

    .react-grid-item {
        border: 1px solid $color-light-border;
        background: white;
        padding: 5px;
    }

    .right-items {
        .add-item-block {
            padding: 5px 10px;
            border: 1px dotted $color-light-blue-border;
            border-radius: 5px;
            margin: 10px 10px 0 10px;
            cursor: pointer;
            h5 {
                font-size: 14px;
                text-align: left;
                font-weight: 400;
            }
            &:hover{
                background: $color-light-blue-border;
                border-style: solid;
            }
        }
    }
}
