@import "../colors";

.chart-general_tab {
    height: 100%;

    background: #ffffff;
    border-left: 1px solid $color-light-border;

    .menu_block-body {
        font-size: 12px !important;

        .ant-checkbox-wrapper {
            font-size: 12px !important;
        }

        .ant-col {
            align-items: center;
            justify-content: left;
            display: flex;

            label {
                text-align: right;
                margin-right: 3px;
            }

            .ant-input-number {
                width: 50px;
            }
        }
    }
}
