@import "../colors";

.param-modal {
    .ant-divider {
        margin-bottom: 5px;

        .ant-divider-inner-text {
            font-size: 13px;
            padding: 0;
        }
    }

    .ant-modal-header {
        div.ant-typography {
            margin-bottom: 0;
        }
    }

    .select-subcontent {
        .ant-form-item-label {
            //Field title
            padding: 0;
        }

        .ant-form-item-explain {
            //Error for fields
            font-size: 12px;
            min-height: 18px;
            line-height: 18px;
        }

        .ant-row.ant-form-item {
            margin-bottom: 0;
        }

        .ant-table-wrapper {
            .ant-table {
                font-size: 12px;

                .ant-table-cell {
                    padding: 3px;
                }
            }

            margin-top: 10px;
        }

        .select-footer {
            margin-top: 5px;
        }
    }

    .sql-subcontent {
        .sql-title {
            margin-bottom: 10px;
            display: flex;

            label {
                font-size: 12px;
                font-weight: bold;
            }

            .anticon {
                margin-left: auto;
                cursor: pointer;
            }
        }

        .sql-editor {
            margin-bottom: 10px;

            .CodeMirror {
                height: 100px;
            }
        }

        .ant-table-wrapper {
            .ant-table {
                font-size: 12px;

                .ant-table-cell {
                    padding: 3px;
                }
            }

            margin-top: 10px;
        }
    }

    .date-subcontent, .date-period-subcontent {
        .ant-input-group-compact {
            display: flex;
        }
    }
}
