@import "../colors";

.sql-subpage {
    height: 100%;

    .sql-editor {
        flex-grow: 1;

        .react-codemirror2 {
            height: 100%;
        }

        .CodeMirror {
            height: 100%;
        }
    }

    .bottom-panel {
        overflow-x: hidden;
        position: relative;

        .ant-spin {
            .ant-spin-text {
                margin-top: 10px;
            }
        }

        .sql-error {
            .error-content {
                padding: 24px 30px;
                background-color: #fafafa;
                margin: 20px;
                border: 1px solid $color-red;

                .error-title {
                    display: flex;
                    margin-bottom: 15px;

                    svg {
                        font-size: 24px;
                        color: $color-red;
                    }

                    div {
                        display: inline-block;
                        font-weight: 400;
                        line-height: 24px;
                        font-size: 20px;
                        margin-left: 10px;
                        color: $color-black;
                    }
                }
            }
        }

        .center-container {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
        }

        .sql-results {
            display: flex;
            flex-direction: column;
            height: 100%;

            .sql-results-toolbar {
                background-color: #fff;
                padding: 5px 5px 5px 20px;
                font-size: 12px;
                font-weight: bold;
                line-height: 30px;
                color: $color-black;

                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                border-left: solid 1px $color-light-border;
                border-right: solid 1px $color-light-border;

                .add-chart-span {
                    margin-left: auto;

                    button {
                        border-radius: 4px;
                        padding-left: 15px;
                        padding-right: 15px;
                        height: 28px;
                    }
                }
            }

            .sql-table-big-container {
                flex-grow: 1;
            }
        }
    }
}

.sql-right-menu {
    overflow-x: scroll;

    .db-selector {
        > * {
            width: 100%;
        }

        padding: 3px 3px 4px 3px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .tables-list {
        font-size: 11px;
        padding: 5px 10px;

        .no-tables {
            padding: 20px;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: $color-black;
        }

        .table-schema {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 3px;
        }
    }
}
