$color-green: #0fa70f;
$color-green-hover: #11c611;
$color-red: #d93012;
$color-black: rgba(0, 0, 0, 0.75);
$color-10p-black: rgba(0, 0, 0, 0.1);
$color-link: #007fe8;

$color-grey-bg: rgb(240, 242, 245);

$color-light-border: $color-10p-black;
$color-light-blue-border: rgba(34, 153, 255, 0.2);
