body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

//@import "~antd/dist/antd.css";

.text-center {
    text-align: center;
}

.hidden {
    display: none;
}

.form-block {
    h1 {
        font-weight: 100;
    }

    .ant-form-item-explain {
        margin-bottom: 10px;
    }

    .ant-form-item-label {
        margin-bottom: 0;
        padding-bottom: 0;

        label {
            height: auto;
            padding: 5px 0 2px 0;
        }
    }

    .ant-alert {
        margin-bottom: 15px;
    }

    .hide-form-fields {
        .ant-form-item {
            display: none;
        }
    }
}

//Loader
.data-loader {
    text-align: center;
    padding: 10vh;
    border: 1px solid #e8e8e8;

    .ant-spin-text {
        margin-top: 20px;
    }
}

//Data load error block
.data-load-error {
    border: 1px solid #e8e8e8;
}

.ant-layout {
    width: 100vw;
}
