.clear-layout {
    min-height: 100vh;
    background: #fff;

    header {
        height: 60px;
        line-height: 60px;

        .logo {
            float: left;
            margin-right: 20px;

            img {
                height: 20px;
                margin-bottom: 4px;
            }
        }
    }

    main {
        text-align: center;
        padding-top: 3vh;
    }

    footer {
        text-align: center;
    }
}
