.main-layout {
    min-height: 100vh;
    background: #fff;

    header {
        height: 60px;
        line-height: 60px;

        .logo {
            float: left;
            margin-right: 20px;

            img {
                height: 20px;
                margin-bottom: 4px;
            }
        }

        .ant-menu {
            .ant-menu-item {
                margin: 15px 5px;
                border-radius: 20px;
                padding: 5px 18px;
                width: auto;
                height: 30px;
                line-height: 20px;
            }

            .ant-menu-item-selected {
                //border: 1px solid #4b4b4b;
                background: rgba(255, 255, 255, 0.15);
            }
        }
    }

    main {
        //background-color: #f0f2f5;
        padding: 20px 50px 0;

        .ant-page-header {
            padding-left: 0;
            padding-right: 0;

            .ant-page-header-heading-title {
                font-weight: 400;
            }
        }

        .ant-empty {
            padding: 3vh 0;
            margin: 0;
            border: 1px solid #e8e8e8;
        }
    }
}
