@import "colors";

.menu_block {
    .menu_block-head {
        display: flex;
        padding: 5px 11px;
        font-size: 11px;

        .menu_block-head-left {
            font-weight: bold;
            color: rgba(0, 0, 0, 0.75);
            text-transform: uppercase;
            margin-right: auto;
        }

        .menu_block-head-right {
        }
    }

    .menu_block-body {
        padding: 5px;
        background-color: rgba(0, 0, 0, 0.02);
    }

    border-bottom: 1px solid $color-light-border;
}
