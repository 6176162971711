.colorpicker {
    border: 1px solid #ccc;
    max-width: 16px;
    border-radius: 100%;

    .picker-full {
        position: absolute;
        z-index: 100;
    }

    .picker-cover {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .colorpicker-color {
        width: 10px;
        height: 10px;
        margin: 2px;
        border-radius: 100%;
        cursor: pointer;
    }
}
