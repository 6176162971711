@import "../colors";

.chart-subpage {
    height: 100%;
    border-left: 1px solid $color-light-border;

    .top-toolbar {
        div,
        button {
            margin-right: 10px;
        }

        .ant-select {
            background: #fff;
            border-radius: 2px;

            .ant-select-selector {
                height: 25px !important;

                .ant-select-selection-item {
                    font-size: 12px;
                }
            }
        }
    }

    .subpage-content {
        display: flex;
        flex-direction: row;
        width: 100%;

        .chart-unconfigured {
            height: 400px;
            width: 100%;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0;
            font-size: 12px;

            .ant-empty-footer {
                margin-top: 0;

                .chart-config-error {
                    font-weight: bold;
                }
            }
        }

        .my-chart {
            .my-chart-inner {
                width: 100%;
            }

            padding: 15px;
            background-color: white;
            flex-grow: 1;
            width: 100%;
        }

        .my-chart-error {
            padding: 15px;
            background-color: white;
            flex-grow: 1;
            height: 400px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0;
        }
    }
}

.chart-right-menu {
    overflow: hidden;
    background-color: $color-grey-bg;

    .right-menu-inner {
        height: 100%;

        .ant-tabs {
            height: 100%;

            .ant-tabs-bar {
                border-bottom: 1px solid $color-light-border;
                margin-bottom: 0;
                margin-top: 2px;

                .ant-tabs-nav-container {
                    height: auto;
                }

                .ant-tabs-nav {
                    font-size: 12px;

                    .ant-tabs-tab {
                        height: 30px;
                        line-height: 16px;
                        padding: 5px 20px;
                        margin: 0;
                    }

                    .ant-tabs-tab-active {
                        height: 30px;
                    }

                    padding: 0 2px;
                }
            }

            .ant-tabs-content {
                height: 100%;

                .ant-tabs-tabpane-active {
                    height: 100%;
                }
            }
        }
    }
}

.col-column {
    padding: 2px 5px;
    margin-bottom: 3px;
    display: flex;
    flex-direction: row;
    border: 1px dotted $color-light-border;
    border-radius: 4px;
    //cursor: pointer;
    background-color: #fff;

    &:first-child {
        margin-top: 0;
    }

    &.col-column-dragging {
        border: 3px solid $color-light-border;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .col-column-name {
        font-weight: bold;
        color: $color-black;
        font-size: 11px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .col-column-type {
        margin-left: auto;
        padding-left: 3px;
        font-size: 11px;
    }
}

.config-param-menu {
    .ant-select-item {
        font-size: 11px;
        padding: 4px 6px;
        min-height: initial;
        line-height: normal;

        .select-maintype {
            font-weight: bold;
        }

        .select-subtype {
        }
    }
}
