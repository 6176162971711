@import "./colors";

.param-dropdown {
    display: inline-block;
    margin-left: 10px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 12px;
    padding: 0;
}

.param-dropdown-overlay {
    li.ant-dropdown-menu-item {
        font-size: 12px;
        line-height: 12px;
        padding: 5px 12px;
    }

    .add-item {
        font-weight: bold;
    }

    .param-item {
        width: 150px;
        display: flex;

        .param-name {
            max-width: 130px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .param-edit {
            margin-left: auto;
            text-align: right;
            color: $color-link;
        }
    }
}
